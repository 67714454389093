import cx from 'classix';
import Image from 'next/image';
import {
  BsStarFill,
  BsStarHalf,
} from 'react-icons/bs';

import { Link } from '@/i18n/routing';
import { DOWNLOAD_APP_LINKS } from '@/shared/constants/links-constants';

import styles from './banner.module.scss';

const fullStars = Array.from({ length: 4 }, (_, index) => index);

interface HomeBannerProps {
  isShow: boolean
}

export default function Banner(props: HomeBannerProps) {
  const { isShow } = props;

  return (
    <div className={cx(styles.banner, !isShow && styles.banner__hidden)}>
      <div className={styles.banner_phone}>
        <Image
          src="/images/banner/phone-en.webp"
          alt="phone banner"
          priority
          fill
        />
      </div>
      <div className={styles.banner_appLinks}>
        <div className={styles.banner_rating}>
          <div className={styles.banner_ratingBackground}>
            <Image
              src="/images/banner/landing-banner.webp"
              alt="landing bannerr"
              fill
            />
          </div>
          <div className={styles.startContainer}>
            {fullStars.map((item) => (
              <div
                key={item}
                className={
                  styles.startContainer_item
                }
              >
                <BsStarFill />
              </div>
            ))}
            <div
              className={styles.startContainer_item}
            >
              <BsStarHalf />
            </div>
          </div>
        </div>
        <div className={styles.marketingList}>
          <Link
            aria-label="appStore"
            className={styles.marketingList_link}
            href={DOWNLOAD_APP_LINKS.appStore}
            rel="nofollow"
            target="_blank"
          >
            <Image
              src="/images/banner/tunes.webp"
              alt="full star"
              fill
            />
          </Link>
          <Link
            aria-label="googlePlay"
            className={styles.marketingList_link}
            href={DOWNLOAD_APP_LINKS.googlePlay}
            rel="nofollow"
            target="_blank"
          >
            <Image
              src="/images/banner/google-play.webp"
              alt="full star"
              fill
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
