import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/currency/components/currency-picker/currency-select/currency-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/language/components/language-picker/language-select/language-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/place/components/search-place/search-place.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/roomster-app/components/app-banner/app-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/roomster-app/components/app-download/app-download.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/layout/header-logined-links/header-logined-links.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/layout/header/header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/menu/menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/briefly/components/briefly-section/briefly-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/cities-gallery/components/cities-gallery-section/cities-gallery-section.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home-intro/components/home-intro-section/home-intro-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home-page-footer-section/home-page-footer-section.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/loader/loader.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/ui/button/button.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/ui/notification-indicator/notifications-indicator.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/in-the-press/components/in-the-press-section/in-the-press-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/popular-cities/components/popular-cities-section/popular-cities.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/root-page.module.scss");
